// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  styled
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import * as Yup from "yup";
import { AddCircleOutline } from "@mui/icons-material";
import { CloseOutlined, ArrowBackIos,ArrowForwardIos } from '@mui/icons-material';
import { Formik, Form, Field, FieldArray } from "formik";

// import { this.getErrorAndHelperText, FormError, FormErrorTouched } from "../../../components/src/Utilities"

import FilteritemsController, {
    Props ,
  } from "./FilteritemsController";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export default class EditProduct extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
      this.setState({ token: await getStorageData("authToken") })
    this.userType();
    console.log("this.props", this.props)
    const productId = this.getQueryParam("navigationBarTitleText");
    console.log("PidPid", productId);
    if (productId) {
      this.getProductDetails(Number(productId));
    }
  }

  productSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    name: Yup.string().required("Name is required"),
    availableQty: Yup.number().required("Available quantity is required").min(1, "Available quantity cannot be negative or zero"),
    type: Yup.string().required("Type is required"),
    classification: Yup.string().required("Classification is required"),
    images: Yup.array().min(1, "At least one image is required"),
    price: Yup.number().required("Price is required").min(1, "Price cannot be negative or zero"),
    specifications: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required("Key is required"),
        value: Yup.string().required("Value is required"),
      })
    ),
  });

  inputStyle = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "& fieldset": {
        borderColor: "#E0E0E0",
      },
      "&:hover fieldset": {
        borderColor: "#BDBDBD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#067B3E",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#757575",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#067B3E",
    },
  };

  isError = (
    touched: boolean | undefined,
    errors: string | undefined,
  ) => {
    return touched && !!errors;
  };
  helperText = (
    touched: boolean | undefined,
    errors: any,
  ) => {
    return touched && errors;
  }

  renderImageSlider = (remove: any, push: any) => {
    const { currentIndex, images, visibleCount, disablePrev } = this.state;
    const visibleImages = images.length && images.slice(visibleCount, visibleCount + 3);
    return (
      <Grid container>
        <button onClick={this.handlePrev} style={{ border: 'none', backgroundColor: 'white' }}
          disabled={disablePrev <= 3}
        >
          <ArrowBackIos />
        </button>

        {visibleImages.map((image: any, index: number) => {
          return (
            <Grid item xs={3} key={image.key}>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  height: 76,
                  width: 76,
                  display: 'flex',
                  padding: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginTop: 3,
                  position: 'relative'
                }}
              >
                {
                /*istanbul ignore next*/
                image.img ? (
                  <>
                    <img
                      src={image.img.url}
                      alt={`Product ${this.state.currentIndex + index}`}
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                    <IconButton
                      size="small"
                      data-test-id="iconButton2"
                      onClick={(e) => {
                      /*istanbul ignore next*/  
                      const  clickHandler = () => {
                        const newImages = [...this.state.images];
                        newImages[image.key] = {
                          img: null,
                          key: image.key
                        };
                        this.setState({ images: newImages });
                        e.stopPropagation();
                        remove(index);
                        this.setSelectedImageIndex(0);
                        }
                        /*istanbul ignore next*/
                        clickHandler()        
                      }}

                      style={{ position: 'absolute', top: 0, right: 0 }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <input
                      accept="image/png, image/jpeg"
                      style={{ display: 'none' }}
                      id={`icon-button-file-${index}`}
                      type="file"
                      data-test-id="uploadImageSlot"
                      onChange={(event) => {
                         /*istanbul ignore next*/
                        const onChangeHandler = () => {
                          this.setState((prevState) => ({
                            currentIndex: currentIndex + 1
                          }));
                          if (event.currentTarget.files) {
                            Array.from(event.currentTarget.files).forEach((file) => {
                              push({
                                url: URL.createObjectURL(file),
                                file,
                              });
                              const newImages = [...this.state.images];
                              newImages[image.key] = {
                                img: {
                                  url: URL.createObjectURL(file),
                                  file,
                                },
                                key: image.key
                              };
                              this.setState({ images: newImages });
                            });
                          }
                        }
                         /*istanbul ignore next*/
                        onChangeHandler()
                      }}
                    />
                    <label htmlFor={`icon-button-file-${index}`}>
                      <IconButton component="span">
                        <AddCircleOutline />
                      </IconButton>
                    </label>
                  </>
                )}
              </Box>
            </Grid>
          )
        }
        )}

        <button
          onClick={this.handleNextSlideImage} style={{ border: 'none', backgroundColor: 'white', marginLeft: '-10px' }}
          disabled={disablePrev === 5}
        >
          <ArrowForwardIos />
        </button>
      </Grid>
    )
  }

  renderUploadImage = (values: any, remove:any, push: any) => {
    return (
      <>
        {
        /*istanbul ignore next*/  
        values.images[this.state.selectedImageIndex] ? (
          <>
            {values.images.length > 1 && (
              <ArrowBackIos
                sx={{ position: 'absolute', top: '50%', left: '2%', cursor: 'pointer' }}
                onClick={
                  /*istanbul ignore next*/
                  () => this.handlePrevImage(values.images.length)
                }
              />
            )}
            <img
              src={values.images[this.state.selectedImageIndex].url}
              alt={`Product ${this.state.selectedImageIndex}`}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
            <IconButton
              size="small"
              data-test-id="iconButton1"
              onClick={() => {
                const newImages = [...this.state.images];
                newImages[this.state.selectedImageIndex] = {
                  img: null,
                  key: this.state.selectedImageIndex
                };
                this.setState({ images: newImages });
                remove(this.state.selectedImageIndex);
                this.setSelectedImageIndex(0);
              }}
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseOutlined />
            </IconButton>
            {values.images.length > 1 && (
              <ArrowForwardIos
                sx={{ position: 'absolute', top: '50%', right: '1%', cursor: 'pointer' }}
                onClick={
                  /*istanbul ignore next*/
                  () => this.handleNextImage(values.images.length)
                }
              />
            )}
          </>
        ) : (
          <>
            <input
              accept="image/png, image/jpeg"
              style={{ display: 'none' }}
              id="icon-button-file-0"
              type="file"
              data-test-id="uploadImage"
              onChange={(event) => {
                /*istanbul ignore next*/
                if (event.currentTarget.files) {
                  const file = event.currentTarget.files[0];
                  push({ url: URL.createObjectURL(file), file });
                  /*istanbul ignore next*/
                  const newImages = [...this.state.images];
                  /*istanbul ignore next*/
                  newImages[0] = {
                    img: {
                      url: URL.createObjectURL(file),
                      file,
                    },
                    key: 0
                  };
                  /*istanbul ignore next*/
                  this.setState({ images: newImages, currentIndex: 1 });
                }
              }}
            />
            <label htmlFor="icon-button-file-0">
              <Button data-test-id="btn-upload-image" style={{ background: 'rgba(6, 123, 62, 1)', textTransform: 'capitalize', fontFamily: "Inter", fontSize: "16px", fontWeight: 700 }} variant="contained" component="span">
                Upload
              </Button>
            </label>
            <Typography variant="caption" sx={{ mt: 1, fontFamily: "Inter", fontSize: "16px", fontWeight: 400, color: "rgba(100, 116, 139, 1)" }}>
              Image : Accepted file format .png, .jpeg
            </Typography>
          </>
        )}
      </>
    )
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        {/* <AppHeader navigtion={this.props.navigation} /> */}
        {/* {this.state.userType === "SellerAccount" && ( */}
          <MainBox sx={{ p: 3, width: '75%', margin: 'auto' }}>
            <Formik
              initialValues={this.state.editProductState}
              validationSchema={this.productSchema}
              onSubmit={(values) => this.handleFormSubmit(values)}
              enableReinitialize
              data-test-id="editFormik"
            >
              {({ errors, touched, values, resetForm, handleSubmit }) => (         
                
                <form onSubmit={handleSubmit} data-test-id="editForm" onReset={() => resetForm()} id="product-form">
                  <>
                  </>
                  <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 40,
                    width: "95%",
                  }}>
                    <Typography variant="h5" fontFamily="Inter" fontWeight="700">{this.getTitle()}</Typography>
                    <Box>
                      <Button
                      id="button"
                        variant="outlined"
                        data-test-id="btn-cancel"
                        sx={{ mr: 2 }}
                        typeof="reset"
                        className="cancelBtn"
                        onClick={()=>this.handleCancelBtnClick()}
                      >
                        Cancel
                      </Button>
                      <Button 
                        // variant="contained"
                        // id="button"
                        type="submit"
                        color="primary"
                        className="saveBtn"
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} style={{ paddingTop: 58 }}>
                    <FieldArray name="images" data-test-id="FieldArray1">
                      {({ push, remove }) => (
                        <>
                          <Box
                            sx={{
                              border: '1px solid #ccc',
                              borderRadius: '10px',
                              p: 2,
                              height: 315,
                              width: 338,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              mb: 2,
                              position: 'relative',
                            }}
                          >
                            {this.renderUploadImage(values, remove, push)}
                          </Box>
                          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            {values.images.map((_, index) => (
                              <div
                                key={index}
                                onClick={
                                   /*istanbul ignore next*/
                                  () => this.setSelectedImageIndex(index)
                                }
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                  background: this.state.selectedImageIndex === index ? 'black' : 'rgba(200, 200, 200, 1)',
                                  margin: '0 5px',
                                  cursor: 'pointer',
                                }}
                              />
                            ))}
                          </div>
                            <div>
                         {this.renderImageSlider(remove, push)}
                          </div>
                        </>
                      )}
                    </FieldArray>
                      {errors?.images && typeof errors?.images === 'string' && (
                        <Typography style={{ margin: "5px 0 0 0 ",
                          fontSize: "0.75rem", fontFamily :"Inter"  }} color="error">{errors?.images}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={8} style={{ paddingLeft: 58, color: "#334155"}}>
                      <Typography variant="subtitle1" fontWeight="700" fontSize="14px" gutterBottom  fontFamily= "Inter">Name</Typography>
                      <Field
                        as={TextField}
                        fullWidth
                        name="name"
                        data-test-id="productName"
                        placeholder="Enter product name"
                        variant="outlined"
                        error={this.isError(touched?.name, errors?.name)}
                        helperText={this.helperText(
                        touched?.name,
                        errors?.name
                        )}
                        sx={{ ...this.inputStyle, mb: 2 }}
                      />
                      <Typography fontWeight="700" fontSize="14px" fontFamily= "Inter" variant="subtitle1" gutterBottom>Description</Typography>
                      <Field
                        as={TextField}
                        fullWidth
                        multiline
                        rows={4}
                        name="description"
                        variant="outlined"
                        error={this.isError(touched?.description, errors?.description)}
                        helperText={this.helperText(
                        touched?.description,
                        errors?.description
                        )}
                        placeholder="Enter product description"
                        sx={{ ...this.inputStyle, mb: 2 }}
                      />
                      <Typography variant="subtitle1" fontSize="14px" id="specification" fontFamily= "Inter"  gutterBottom fontWeight="700">Specifications</Typography>
                          <Grid container sx={{ mb: 2 }} justifyContent="space-between" display={this.isShowSpecificationAgeInput() ? "" : "none"}>
                                <Grid item xs={5} id="specification">
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    name="age"
                                    placeholder="Yield"
                                    variant="outlined"
                                    sx={this.inputStyle}
                                    disabled
                                    InputProps={{
                                      sx: {
                                        "& .MuiInputBase-input::placeholder": {
                                          opacity: 1,
                                          "-webkit-text-fill-color": "rgba(15, 23, 42)",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    name="age_value"
                                    placeholder="Enter Value"
                                    variant="outlined"
                                    sx={this.inputStyle}
                                  />
                                </Grid>
                                <Grid item xs={2} mt={1} style={{ maxWidth: "fit-content"}} onClick={this.handleAgeInput}>
                                  <IconButton data-test-id="removeIcon">
                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.79 0.29L10.5 1H13C13.55 1 14 1.45 14 2C14 2.55 13.55 3 13 3H1C0.45 3 0 2.55 0 2C0 1.45 0.45 1 1 1H3.5L4.21 0.29C4.39 0.11 4.65 0 4.91 0H9.09C9.35 0 9.61 0.11 9.79 0.29ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM4 6H10C10.55 6 11 6.45 11 7V15C11 15.55 10.55 16 10 16H4C3.45 16 3 15.55 3 15V7C3 6.45 3.45 6 4 6Z" fill="#0F172A"/>
                                    </svg>
                                  </IconButton>
                                </Grid>
                          </Grid>
                          <Grid container sx={{ mb: 2 }} justifyContent="space-between" display={this.isShowSpecificationFloweringInput() ? "" : "none"}>
                                <Grid item xs={5} id="specification">
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    name="Flowering_time"
                                    placeholder="Flowering time"
                                    variant="outlined"
                                    sx={this.inputStyle}
                                    disabled
                                    InputProps={{
                                      sx: {
                                        '& .MuiInputBase-input::placeholder': {
                                          "-webkit-text-fill-color": "rgba(15, 23, 42)",
                                          opacity: 1,   
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    name="Flowering_time_value"
                                    placeholder="Enter Value"
                                    variant="outlined"
                                    sx={this.inputStyle}
                                  />
                                </Grid>
                                <Grid item xs={2} mt={1} style={{ maxWidth: "fit-content"}} onClick={this.handleFloweringInput}>
                                  <IconButton data-test-id="removeIcon">
                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.79 0.29L10.5 1H13C13.55 1 14 1.45 14 2C14 2.55 13.55 3 13 3H1C0.45 3 0 2.55 0 2C0 1.45 0.45 1 1 1H3.5L4.21 0.29C4.39 0.11 4.65 0 4.91 0H9.09C9.35 0 9.61 0.11 9.79 0.29ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM4 6H10C10.55 6 11 6.45 11 7V15C11 15.55 10.55 16 10 16H4C3.45 16 3 15.55 3 15V7C3 6.45 3.45 6 4 6Z" fill="#0F172A"/>
                                    </svg>
                                  </IconButton>
                                </Grid>
                          </Grid>
                      <FieldArray name="specifications" data-test-id="FieldArray2">
                        {({ push, remove }) => (
                          <>
                            {values.specifications.sort((a:any, b:any) => a.id - b.id).map((spec, index) => (
                              <Grid container key={index} sx={{ mb: 2 }} justifyContent="space-between">
                                <Grid item xs={5} id="specification">
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    name={`specifications.${index}.key`}
                                    placeholder="Enter Key"
                                    variant="outlined"
                                    sx={this.inputStyle}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    name={`specifications.${index}.value`}
                                    placeholder="Enter Value"
                                    variant="outlined"
                                    sx={this.inputStyle}
                                  />
                                </Grid>
                                <Grid item xs={2} mt={1} style={{ maxWidth: "fit-content"}}>
                                  <IconButton onClick={() => remove(index)} data-test-id="removeIcon">
                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.79 0.29L10.5 1H13C13.55 1 14 1.45 14 2C14 2.55 13.55 3 13 3H1C0.45 3 0 2.55 0 2C0 1.45 0.45 1 1 1H3.5L4.21 0.29C4.39 0.11 4.65 0 4.91 0H9.09C9.35 0 9.61 0.11 9.79 0.29ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM4 6H10C10.55 6 11 6.45 11 7V15C11 15.55 10.55 16 10 16H4C3.45 16 3 15.55 3 15V7C3 6.45 3.45 6 4 6Z" fill="#0F172A"/>
                                    </svg>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                            <Button
                              onClick={() => push({ key: '', value: '' })}
                              variant="text"
                              color="primary"
                              sx={{ mb: 2 }}
                              className="addAnotherBtn"
                              data-test-id="addAnotherSpec"
                            >
                              Add Another Specification
                            </Button>
                          </>
                        )}
                      </FieldArray>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1" fontWeight="700" fontFamily= "Inter" fontSize="14px" gutterBottom>Available Qty</Typography>
                          <Field
                            as={TextField}
                            fullWidth
                            type="number"
                            name="availableQty"
                            placeholder="Enter available quantity"
                            variant="outlined"
                            error={
                              /*istanbul ignore next*/
                              touched?.availableQty && !!errors?.availableQty}
                            helperText={
                              /*istanbul ignore next*/
                              touched?.availableQty && errors?.availableQty}
                            sx={this.inputStyle}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight="700" variant="subtitle1" fontFamily= "Inter" fontSize="14px" gutterBottom>Price</Typography>
                          <Field
                            as={TextField}
                            fullWidth
                            type="number"
                            name="price"
                            placeholder="Enter price"
                            variant="outlined"
                            error={
                              /*istanbul ignore next*/
                              touched?.price && !!errors?.price }
                            helperText={
                              /*istanbul ignore next*/
                              touched?.price && errors?.price}
                            sx={this.inputStyle}
                          />
                        </Grid>
                      </Grid>
                      <Typography fontSize="14px" variant="subtitle1" gutterBottom style={{ marginTop: 16, fontSize:"14px",fontWeight:700, fontFamily:"Inter" }}>Classification</Typography>
                      <Field
                        as={TextField}
                        select
                        fullWidth
                        data-test-id = 'classification1'
                        placeholder="Select Classification"
                        name="classification"
                        error={this.isError(touched?.classification, errors?.classification)}
                      helperText={this.helperText(
                        touched?.classification,
                        errors?.classification
                      )}
                        variant="outlined"
                        sx={this.inputStyle}
                        SelectProps={{
                          displayEmpty: true,
                          renderValue: (selected:any) => {
                            if (!selected) {
                              return <span style={{ color: 'rgb(165, 165, 165)' }}>Select Classification</span>;
                            }
                            return selected;
                          },
                        }}
                      >
                        <MenuItem value="" id="classification" disabled>Select Classification</MenuItem>
                        <MenuItem value="indica">Indica</MenuItem>
                        <MenuItem value="sativa">Sativa</MenuItem>
                        <MenuItem value="hybrid">Hybrid</MenuItem>
                      </Field>
                      <Typography variant="subtitle1" gutterBottom style={{ fontFamily: "Inter",fontSize:"14px" ,marginTop: 16,fontWeight:700 }}>Type</Typography>
                      <Field
                        as={TextField}
                        select
                        fullWidth
                        name="type"
                        placeholder="Select type"
                        data-test-id = 'type1'
                        variant="outlined"
                        error={this.isError(touched?.type, errors?.type)}
                      helperText={this.helperText(
                        touched?.type,
                        errors?.type
                      )}
                        sx={this.inputStyle}
                        SelectProps={{
                          displayEmpty: true,
                          renderValue: (selected:any) => {
                            if (!selected) {
                              return <span style={{ color: 'rgb(165, 165, 165' }}>Select type</span>;
                            }
                            return selected.charAt(0).toUpperCase() + selected.slice(1);
                          },
                        }}
                      >
                        <MenuItem value="" id="type" disabled>Select type</MenuItem>
                        <MenuItem value="seeds">Seeds</MenuItem>
                        <MenuItem value="clones">Clones</MenuItem>
                        <MenuItem value="teens">Teens</MenuItem>
                        <MenuItem value="breeders_cuts">Breeders Cuts</MenuItem>   
                      </Field>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </MainBox>
        {/* )} */}
      </>
      // Customizable Area End
    );
  }
}

const MainBox = styled(Box)({
  "& .addAnotherBtn": {
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    fontWeight: 400
  },
  "& .cancelBtn": {
    borderRadius: "25px",
    padding: "10px 50px",
    height: "44px",
    background: "#E2E8F0",
    border: "1px solid #E2E8F0",
    color: "rgba(30, 41, 59, 1)",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    fontWeight: 700,
    "&:hover": {
      background: "#E2E8F0",
      border: "1px solid #E2E8F0",
    }
  },
  "& .saveBtn": {
    padding: "10px 50px",
    borderRadius: "25px",
    border: "1px solid #067B3E",
    background: "#067B3E",
    color: "white",
    height: "44px",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    fontWeight: 700,
    "&:hover": {
      background: "#067B3E",
    }
  },
})
// Customizable Area End
