Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";
exports.StripeApiFeatch = 'bx_block_custom_user_subs/subscriptions'
exports.CreateCustomer = 'bx_block_subscription_billing/recurring_subscriptions'
exports.UserInfoApi = 'account_block/accounts/logged_user'
exports.publishment = 'pk_live_51Ppinq09SjWANqyBaTJ2oHH6s74exMEHJzTQJEousK4GqxnEhrKrxyWpCCJeYTelB8s8lakw8in8Z0ImayFQEiVe00fbRrCK2A'
exports.testToken = 'pk_test_51Ppinq09SjWANqyB0rNDxgnKoSWrGK9NtAcbQQMMroe1zmo56kjMa5sgbNBCif4BDtM48sj8z81qzxtAcE8MOUQl00JJbee2Nn'
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End