import React from 'react';
const Logo = require('./Logo.png')


function LogoImage() {

     return (
          <>
               <img src={Logo.default} style={{ height: 'auto', width: '126px', marginBottom: '10px' }} />
          </>
     );
}

export default LogoImage;